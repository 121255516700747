import Vue from 'vue'
import i18n from './i18n'
import axios from 'axios'
import App from './App.vue'
import store from './store'
import router from './router'
import VueAxios from 'vue-axios'
import CoreuiVue from '@coreui/vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSimpleAlert from "vue-simple-alert";
import { iconsSet as icons } from './assets/icons/icons.js';

const options = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
};

Vue.use(CoreuiVue);
Vue.use(VueSimpleAlert);
Vue.use(Toast, options);
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

const user = JSON.parse(localStorage.getItem('user', null));

if (user) {
    store.dispatch('setUser', user);
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
} else {
    store.dispatch('setUser', null);
}

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

axios.get('/api/users')
    .then(response => {
        console.log(response);
        store.dispatch('setUser', response.data);
    }).catch(() => {
        store.dispatch('setUser', null);
    }).finally(() => {
        new Vue({
          i18n,
          store,
          icons,
          router,
          render: h => h(App)
        }).$mount('#app')
    });

