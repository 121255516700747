import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user:null
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        }
    },
    actions: {
        setUser({commit}, user) {
            localStorage.setItem('user', JSON.stringify(user));
            commit('setUser', user);
        }
    },
    getters: {
        isAuthenticated: (state) => {
            return (state.user);
        },
    }
})
