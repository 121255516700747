import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

const TheContainer = () => import('@/containers/TheContainer');

const Payroll = () => import('@/views/payroll/Payroll');
const GlobalPayroll = () => import('@/views/payroll/Global');

const Employees = () => import('@/views/employees/Employees');
const EmployeeCreate = () => import('@/views/employees/Create');

const Incidences = () => import('@/views/incidences/Incidences');
const IncidenceCreate = () => import('@/views/incidences/Create');

const Locations = () => import('@/views/locations/Locations');
const LocationCreate = () => import('@/views/locations/Create');

const Categories = () => import('@/views/categories/Categories');
const CategoryCreate = () => import('@/views/categories/Create');

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/',
        component: TheContainer,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'global-payroll',
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        name: 'Nomina Global',
                        component: GlobalPayroll,
                    },
                ]
            },
            {
                path: 'payroll',
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        name: 'Nomina',
                        component: Payroll,
                    },
                ]
            },
            {
                path: 'categories',
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        name: 'Categories',
                        component: Categories,
                    },
                    {
                        path: 'add',
                        name: 'CategoryCreate',
                        component: CategoryCreate
                    },
                    {
                        path: ':id/edit',
                        name: 'CategoryEdit',
                        component: CategoryCreate
                    }
                ]
            },
            {
                path: 'incidences',
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        name: 'Incidences',
                        component: Incidences,
                    },
                    {
                        path: 'add',
                        name: 'IncidenceCreate',
                        component: IncidenceCreate
                    },
                    {
                        path: ':id/edit',
                        name: 'IncidenceEdit',
                        component: IncidenceCreate
                    }
                ]
            },
            {
                path: 'locations',
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        name: 'Locations',
                        component: Locations,
                    },
                    {
                        path: 'add',
                        name: 'LocationCreate',
                        component: LocationCreate
                    },
                    {
                        path: ':id/edit',
                        name: 'LocationEdit',
                        component: LocationCreate
                    }
                ]
            },
            {
                path: 'employees',
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        name: 'Employees',
                        component: Employees,
                    },
                    {
                        path: 'add',
                        name: 'EmployeeCreate',
                        component: EmployeeCreate
                    },
                    {
                        path: ':id/edit',
                        name: 'EmployeeEdit',
                        component: EmployeeCreate
                    }
                ]
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (store.getters.isAuthenticated) {
            next()
        } else {
            next({ name: 'Login' })
        }
    } else {
        next()
    }
});

export default router
